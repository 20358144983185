@font-face {
  font-family: "PTSans-Narrow";
  src: url('/static/fonts/PTSans-Narrow.otf') format('opentype'),
  url('/static/fonts/PTSans-Narrow.woff') format('woff'),
  url('/static/fonts/PTSans-Narrow.woff2') format('woff2'); 
}
@font-face {
  font-family: "PTSans-NarrowBold";
  src: url('/static/fonts/PTSans-NarrowBold.otf') format('opentype'),
  url('/static/fonts/PTSans-NarrowBold.woff') format('woff'),
  url('/static/fonts/PTSans-NarrowBold.woff2') format('woff2'); 
}