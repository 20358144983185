@import 'color';

.sp-event {


  &:hover { background-color:  pole_condition($c-lighter, $c-darker); }

  & ~ .sp-event { margin-top: 10px !important; }

  .sp-event-header {
    text-align: left;
    padding-left: 5px !important;
    margin-bottom: 5px !important;
    font-family: PTSans-Narrow;

    .sp-event-time,
    .sp-event-local,
    .sp-event-team1,
    .sp-event-team2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .sp-event-time { 
      font-family: PTSans-NarrowBold;
      @include color-active();
    }

    .sp-event-local {
      font-size: .6rem;
      color: pole_condition($c-darker, $c-lighter);
      line-height: 1;
      margin-bottom: .5em;
    }
    .sp-event-team1, .sp-event-team2 {
      line-height: 1;
    }
  }

  .sp-firstodd button:not([ignore~="grid"]) {
    text-align: right;
    span:not(.sp-icon) {
      float: left;
      text-align: left;
    }

    @media (min-width: 576px) {
      text-align: center;
      span:not(.sp-icon) { display: none; }
    }
  }

  .sp-icon {
    display: none;
    img { display: block; }



    &[btype='STATS'] {
      color: $c-pole-light;
      height: 2.5rem;
      img { margin: auto; }
      svg { width: 1.5rem; }
    }

    &[btype='EXTRA'] {
      color: $c-pole-light;
      height: 2.5rem;
      img {
        height: 1rem;
        display: inline;
      }
      svg {
        display: block;
        margin: auto;
        width: 0.875rem;
      }
    }

    // live
    &.sp-arrowup { 
      position: absolute;
      height: 8px;
      width: 8px;
      top: 2px;
      right: 2px;
      img { display: block; }
    }
    &.sp-arrowdown {
      @extend .sp-arrowup;
      top: initial;
      bottom: 2px;
      @include rotate(180deg); 
    }
    // *****
      
    // future events / results
    &.sp-icon-sportid {
     vertical-align: middle;
     height: 1em;
     margin: 0 .25em .25em 0;
    }
    // *****


    // results
    &.sp-sport-icon {
      width: 35px;
      height: 35px;
    }

    &.sp-lens-icon {
      height: 3rem;
      width: 90%;

      @media (min-width: 768px){
        width: 35px;
        height: 35px;
      }
    }
    // *****
  }
}

.sp-event-specialbets {
  margin-top: 10px;
  @media (max-width: 575px){ margin-top: 3px; }

  .sp-betmode {
    margin-top: .5rem !important;
    padding: 5px 0 !important;

    /*&:nth-child(2n+1) {
      background-color: lightgray; 
    }

    & .sp-betmode {
      margin-top: 10px !important;
    }*/

    &:hover {
      background-color: $c-darky;
    }

    .sp-icon[data-toggle="tooltip"] img{ 
      width: 30px;
      height: 30px;
    }

    .sp-betmode-head { 
      text-align: left; 
      padding-left: 5px !important;
      font-family: PTSans-Narrow;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .sp-betmode-main {
      button {
        text-align: right;
        span:not(.sp-icon) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 70%;
          text-align: left;
          float: left;
        }
      }
      margin-top: 10px;
      @media (min-width: 992px){ margin: 0; }

      /*&>div>div+div+div+div { margin-top: 5px; }*/
    }
  }
}

.gamedetail:empty {
  height: 0;
}


.sp-event-tooltip {

  display: inline-block;
  position: relative;
  font-size: 1.25rem;
  // font-family: PTSans-NarrowBold;
  color: $c-body;
  z-index: 2;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.6;
  text-align: center;

  &::before {

    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50%);
    z-index: -1;
    width: 1.875rem;
    height: 1.875rem;

    border-radius: 50%;

    @include bg-img-secondary();
  }
}