@import 'color';

.sp-grid {

	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-right: 10px;
	float:left;
	font-weight: bolder;
	// overflow: hidden;
	width: 100%;
}

.sp-block {
	
	@include bg-color-default();
	width: 100%;
	text-align: center;
	// color: $c-pole-dark;

	&+.sp-block { margin-top: 10px; }

	.row { margin: 0; }


	@for $i from 1 through 12 {
		.col-#{$i} { padding: 0; }
	} 
	.col, .row { padding: 0; }

	button:not([ignore~="grid"]) {
		font-family: PTSans-Narrow;
		text-align: center;
		font-size: 1em;
		width: 90%;
		margin:	auto 0;
	}
}

.sp-block-title {
	text-align: left;
	background-color: $c-header;
	color: white;
	padding: .4rem .5rem;
	font-size: 1rem;
	font-family: PTSans-NarrowBold;
}

.sp-block-main { 
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 5px;
	* { white-space: nowrap; } 
}

.sp-block-header {
	@extend .sp-block-main;
	font-family: PTSans-NarrowBold;
	&>div:first-child {
		text-align: left;
		padding-left: 5px;
	}
	* { white-space: normal; } 
}