@import "color";

.sp-menu-sports {
  width: 100%;
  overflow: hidden;
  // height: 72px;
  @include bg-color-default();
  font-family: PTSans-NarrowBold;

  @media (min-width: 992px) {
    height: 34px;
  }

  .sp-menu-sports-item {
    white-space: nowrap;
  }

  ul {
    box-sizing: content-box;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
    /*display: flex;*/
    /*flex-flow: row;*/
    display: block;
    white-space: nowrap;

    li {
      text-transform: none;
      padding: 2px 10px;
      cursor: pointer;
      background-color: $c-item-1;
      display: inline-block;
      white-space: normal;
      @media (min-width: 992px) {
        float: left;
      }

      &.active {
        @include bg-color-active();
        a {
          color: white;
        }
        &:hover,
        &:active {
          color: white;
        }
      }

      a {
        min-width: 100px;
        text-align: center;
        padding: 0;
        line-height: 30px;
        color: black;
        font-weight: bolder;

        @media (min-width: 992px) {
          min-width: 0;
        }
      }
    }
  }

  .sp-icon {
    width: 100%;
    height: 25px;
    text-align: center;

    @media (min-width: 992px) {
      width: 25px;
      height: 100%;
    }
  }

  // *[icon][icon="arrow-black"] { height: 20px; }

  .sp-menu-sports-other-compact {
    display: none;

    @media (min-width: 992px) {
      display: inline-block;
    }

    .sp-icon {
      @include rotate(180deg);
      img {
        height: 14px;
      }
    }
  }

  .sp-menu-language {
    @extend .sp-menu-sports-other-compact;
    margin-left: auto;
    float: right;
    .sp-icon:last-child {
      @include rotate(0deg);
      img {
        height: 25px;
      }
    }
  }

  .sp-menu-sports-other {
    display: inline-block;
    @media (min-width: 992px) {
      display: none;
    }
  }
}

$category-width: 10.9375rem;
$category-margin: 0.5rem;
.sp-category-list {
  color: $c-pole-dark;
  display: block;
  float: left;
  font-family: PTSans-NarrowBold;
  font-size: 1.5em;
  line-height: 2;
  margin-right: $category-margin;
  text-align: center;
  width: 100%;

  &:not(.sp-undefined-category) {
    display: none;
  }

  @media (min-width: 992px) {
    &:not(.sp-undefined-category) {
      display: block;
    }
    font-family: PTSans-Narrow;
    font-size: 1em;
    line-height: 1.5;
    width: $category-width;
  }

  li {
    background-color: $c-item-2;
    border-radius: 0;

    padding: 5px;

    &:first-child {
      border-radius: 0;

      display: block;
      text-align: center;
      a {
        height: 100%;
        cursor: pointer;

        img,
        svg {
          height: 1.3125rem;
          width: 1.3125rem;
          @media (min-width: 992px) {
            height: 0.875rem;
            width: 0.875rem;
          }
        }

        &:first-child {
          float: left;
          @include rotate(270deg);
        }
        &:last-child {
          float: right;
          @include rotate(90deg);
        }
      }
    }

    &:last-child {
      border-radius: 0;
    }

    &:not(:first-child) {
      text-align: left;
      cursor: pointer;
      &:hover {
        background-color: $c-item-1;
      }
      span:not(.sp-icon) {
        width: calc(100% - 2.25rem);
        @media (min-width: 992px) {
          width: calc(100% - 1.5rem);
        }
      }
      .sp-icon {
        display: inline-flex;
        font-size: 4.6875rem;
        @media (min-width: 992px) {
          font-size: 3.125rem;
        }
      }
    }
  }

  // .sp-icon {
  // 	width: 30px;
  // 	height: 30px;

  // 	@media (min-width: 992px){
  // 		width: 20px;
  // 		height: 20px;
  // 	}
  // }
}

.sp-sports {
  color: $c-pole-dark;
  float: none;
  margin: 0;
  vertical-align: top;

  &.sp-undefined-category {
    display: none;
  }

  // @media (min-width: 768px){
  // 	width: calc(100% - 10px - 225px);
  // }
  @media (min-width: 992px) {
    // width: calc(100% - 10px - 225px - 185px);
    width: calc(100% - #{$category-margin + $category-width});
    &.sp-undefined-category {
      display: flex;
    }
  }

  .sp-event {
    .sp-icon {
      &[btype="STATS"],
      &[btype="EXTRA"] {
        display: inline-block;
      }
    }
  }
  .sp-event-specialbets {
    & + hr {
      // width: 100%;
      margin: 10px 20px;
    }
  }
}

@mixin order-shortcut($some-number) {
  -webkit-order: $some-number;
  -moz-order: $some-number;
  -ms-order: $some-number;
  order: $some-number;
}
#livedetail-view .sp-event-specialbets > div {
  @include order-shortcut(99);

  &[bettype="7"] {
    @include order-shortcut(1);
  }
  &[bettype="8"] {
    @include order-shortcut(2);
  }
  &[bettype="18"] {
    @include order-shortcut(3);
  }
  &[bettype="61"] {
    @include order-shortcut(4);
  }
  &[bettype="62"] {
    @include order-shortcut(5);
  }
  &[bettype="74"] {
    @include order-shortcut(6);
  }
}

.sp-sports-favorites-header {
  max-width: calc(75% - 1.875rem - 0.5rem);
  @media (min-width: 576px) {
    max-width: 100%;
  }
}

.sp-sports-favorite-button {
  background: none;
  border: none;
  padding: 0;
  height: 1.875rem;
  width: 1.875rem;
  margin: auto 0.25rem;

  & > * {
    font-size: 4.6875rem !important;
  }

  &:not(.active) {
    .BSi-Favorites_Inactive {
      display: inline-flex;
    }
    .BSi-Favorites_Active {
      display: none;
    }
  }

  &.active {
    .BSi-Favorites_Inactive {
      display: none;
    }
    .BSi-Favorites_Active {
      display: inline-flex;
    }
  }
}

.sp-sports-favorites-event {
  @include order-shortcut(-1 !important);
}

.sp-sports-publicity {
  display: none;
  position: relative;
  vertical-align: top;
  width: 100%;

  &:not(:empty) {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  & > div {
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (min-width: 1200px) {
    width: calc(100% - #{$category-margin + $category-width});
  }
}

.sp-sports-highlight-image {
  background: #383638;
  overflow: hidden;
  // padding-bottom: 13.333%;
  position: relative;
  width: 66.66%;
}
