@import 'color';

@mixin arrow_icon() {
	.sp-icon {
    &.sp-arrowup { 
      position: absolute;
      height: 8px;
      width: 8px;
      top: 2px;
      right: 2px;
      img { display: block; }
    }
    &.sp-arrowdown {
      @extend .sp-arrowup;
      top: initial;
      bottom: 2px;
      @include rotate(180deg); 
    }
	}
}

.sp-live {

	@media (min-width: 768px){ width: calc(100% - 10px - 225px); }
	color: $c-pole-dark;

	.sp-block-title .sp-icon {
		height: 1em;
		width: 1em;
		margin-right: 10px;
	}

	.sp-event {

		.sp-event-score {
			text-align: center;
			@include bg-color-active();
			color: white;
			// height: 90%;
			// font-size: 2rem;
			// line-height: 1.8;
			font-size: 1.5rem;
			line-height: 2.4;
		}

		.sp-event-firstperiod {
			text-align: center;
			@include color-active();
			// height: 90%;
			font-size: 1.3rem;
			line-height: 2.5;
			text-transform: uppercase;
			font-family: PTSans-NarrowBold;
		}

		.sp-icon {
			&[btype='EXTRA'] .BSi-base { @include rotate(-90deg); }
			&[btype='EXTRA'],
			&.sp-arrowup,
			&.sp-arrowdown
			{ display: inline-block; }
		}

		* { white-space: nowrap; }
	}

	.sp-upcoming {
		margin-top: 10px;
		.sp-event .sp-icon {
			display: none;

			&[btype='EXTRA'] .BSi-base { @include rotate(0deg); }

			&[btype="STATS"],
			&[btype="EXTRA"],
			&.sp-icon-sportid
			{ display: inline-block; }
		}
	}
}

.sp-live-detail{ display: none; }
	
.sp-live-others+.sp-live-detail{
	display: flex;
	width: 100%;
	@media (min-width: 768px){ width: calc(100% - 10px - 225px); }
	@media (min-width: 992px){ width: calc(100% - 10px - 300px - 10px - 225px); }

	@media (max-width: 1199px) {
		@for $i from 1 through 12 {
			.col-#{$i} {
				-webkit-box-flex: 0;
				-webkit-flex: 0 0 (100% * $i / 12) !important;
				-ms-flex: 0 0 (100% * $i / 12) !important;
				flex: 0 0 (100% * $i / 12) !important;
				max-width: (100% * $i / 12) !important;
			}
		} 
		.hidden-xs-down, .hidden-sm-down, .hidden-md-down,
		.hidden-lg-down, .hidden-xl-down, .hidden-xs-up { display: none; }
		.hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up { display: block !important; }
		$spacer-x: 1rem;
		.mt-0 { margin-top: $spacer-x * 0 !important; }
		.mt-1 { margin-top: $spacer-x * .25 !important; }
		.mt-2 { margin-top: $spacer-x * .5 !important; }
		.mt-3 { margin-top: $spacer-x * 1 !important; }
		.mt-4 { margin-top: $spacer-x * 1.5 !important; }
		.mt-5 { margin-top: $spacer-x * 3 !important; }
	}

	.sp-event-specialbets { 

		& > [bettype]:not([bettype=""]) {
	    -webkit-order: 5;
	    -ms-flex-order: 5;
	    order: 5;

	    &[bettype="L1X2"] {
				-webkit-order: 0;
				-ms-flex-order: 0;
				order: 0;
		  }

	    &[bettype="LES13"] {
				-webkit-order: 1;
				-ms-flex-order: 1;
				order: 1;
		  }

	    &[bettype="LES22"] {
				-webkit-order: 2;
				-ms-flex-order: 2;
				order: 2;
		  }

	    &[bettype="LES285"] {
				-webkit-order: 3;
				-ms-flex-order: 3;
				order: 3;
		  }

	    &[bettype="LOU"] {
				-webkit-order: 4;
				-ms-flex-order: 4;
				order: 4;
		  }
		}

		@include arrow_icon(); 

		.sp-betmode-main {
			@media (max-width: 1199px) { margin-top: 10px;}
		}
	}

	.sp-aspect-ratio {
	  position: relative;
	  width: 100%;
	  // height: 0; retirado o ratio forcado
	  // padding-bottom: 56.25%;

	  iframe {
		  position: absolute;
		  width: 100%;
		  height: 100%;
		  left: 0; top: 0;
	  }
	}
}
.sp-live-others {
	display: none;
	@media (min-width: 992px){ 
		display: flex;	
		width: 300px; 

		.sp-firstodd button {
			text-align: right !important;
			span:not(.sp-icon) {
				display: block !important;
				float: left !important;
				text-align: left !important;
			}
		}
	}

	.sp-upcoming .sp-betmode-main { 
		$spacer-x: 1rem;
		margin-top: 10px !important;
		.mt-0 { margin-top: $spacer-x * 0 !important; }
		.mt-1 { margin-top: $spacer-x * .25 !important; }
		.mt-2 { margin-top: $spacer-x * .5 !important; }
		.mt-3 { margin-top: $spacer-x * 1 !important; }
		.mt-4 { margin-top: $spacer-x * 1.5 !important; }
		.mt-5 { margin-top: $spacer-x * 3 !important; }
	}

	.sp-block-header { display: none; }

	@for $i from 1 through 12 {
		.col-#{$i} {
			-webkit-box-flex: 0;
			-webkit-flex: 0 0 (100% * $i / 12) !important;
			-ms-flex: 0 0 (100% * $i / 12) !important;
			flex: 0 0 (100% * $i / 12) !important;
			flex: 0 0 (100% * $i / 12) !important;
			max-width: (100% * $i / 12) !important;
		}
	} 
	.hidden-xs-down, .hidden-sm-down, .hidden-md-down,
	.hidden-lg-down, .hidden-xl-down, .hidden-xs-up { display: none; }
	.hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up { display: block !important; }
}

.sp-event-paused-color { color: #ffdb24 !important;}
.sp-event-paused-bg-color { background-color: #ffdb24 !important;}

.sp-live-cards { 
	
	position: relative;

	& span {

		position: absolute; 
		top: 50%; 
		left: 50%; 
		transform: translate( -50%, -50%); 
		font-size: .8rem; 
	}

	&.card-yr span { left: 40%; }
} 