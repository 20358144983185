@import 'color';

.sp-modal {
	font-family: PTSans-NarrowBold;

	.modal-dialog {
		@media (min-width: 576px) {
			max-width: 90%;
			width: 1000px;
		}
	}

	.modal-content {
		border-radius: 5px 5px 0 0;
		max-height: calc(100vh - 60px);

		.modal-header select {
			width: 150px;
			margin-left: auto;
			margin-right: 20px;
			font-family: PTSans-NarrowBold;
		}

		.sp-betslip-back {
			width: 2em;
			height: 2em;
			* { @include rotate(-90deg); }
		}
		
		.modal-body {

			&>div+div { margin-top: 10px; }

			input {
				width: 100%;
				border: 1px solid grey;
				border-radius: .25em;
				padding: 0 .5em;
			}

			&.sp-modal-gridy{
				padding-left: 0;
				padding-right: 0;
				overflow: scroll;

				.sp-grid {
					margin: 0;
					background: none;
					min-width: 800px;

					&+.sp-grid { margin-top: 50px; }

					&.sp-ticket-detail { font-family: PTSans-Narrow; }

					.sp-block { 
						background: none;
						text-align: left;
					}

					.sp-block-main {
						padding: 0;
						font-family: PTSans-Narrow;

						* {
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.sp-neeeded-info {
							text-overflow: initial;
							white-space: normal;
						}

						.sp-ticket {
							min-height: 3.125rem;
							padding: 0 5px;

							button {
								font-family: PTSans-NarrowBold; 
								height: 35px;
							}
							&:nth-child(2n+1) { background-color: #E2E2E2; }
						}
					}
				}
			}
		}

		.modal-footer { 
			button { padding: 8px .8rem; }
			border-top: 1px solid $c-darky;
			background-color: #E2E2E2;
			// @include bg-color-default();

			&.sp-footer-center { justify-content: center; }
		
			.sp-icon{
				width: 50px;
				height: 50px;
			}
		}
	}

	.sp-modal-stats {
		background-color: white;
		width: calc(100% - 40px);
		height: calc(100% - 50px);
		margin-top: 25px;

		max-width: 1200px;

		button {
			position: absolute;
			right: -17.5px;
			top: -17.5px;
			width: 35px;
			height: 35px;
			background: white;
			border-radius: 50%;
			// border: 2px solid black;
			padding: 5px;
			text-align: center;
			opacity: 1;
			
			span {
				line-height: 15px;
				font-size: 2rem;
			}
		}

		.sp-modaliframe{

			width:100%;
			height:100%;
			overflow-x: hidden;
			overflow-y:scroll;
			-webkit-overflow-scrolling: touch;
			iframe {
				
				width: 100%;
				height: 100%;
			}
		}
	}
}

@-webkit-keyframes loading-rot {
	0%   {transform: rotateZ(0deg);}
	12.5%   {transform: rotateZ(45deg);}
	25%   {transform: rotateZ(90deg);}
	37.5%   {transform: rotateZ(135deg);}
	50%   {transform: rotateZ(180deg);}
	62.5%   {transform: rotateZ(225deg);}
	75%   {transform: rotateZ(270deg);}
	87.5%   {transform: rotateZ(315deg);}
	100%   {transform: rotateZ(0deg);}
}
@keyframes loading-rot {
	0%   {transform: rotateZ(0deg);}
	12.5%   {transform: rotateZ(45deg);}
	25%   {transform: rotateZ(90deg);}
	37.5%   {transform: rotateZ(135deg);}
	50%   {transform: rotateZ(180deg);}
	62.5%   {transform: rotateZ(225deg);}
	75%   {transform: rotateZ(270deg);}
	87.5%   {transform: rotateZ(315deg);}
	100%   {transform: rotateZ(0deg);}
}

.sp-loading-icon {
	width: 50px;
	height: 50px;
	-webkit-animation: loading-rot .5s infinite; /* Safari 4+ */
	-moz-animation:    loading-rot .5s infinite; /* Fx 5+ */
	-o-animation:      loading-rot .5s infinite; /* Opera 12+ */
	animation:         loading-rot .5s infinite; /* IE 10+, Fx 29+ */
	-webkit-animation-timing-function: step-start; /* Safari 4+ */
	-moz-animation-timing-function:    step-start; /* Fx 5+ */
	-o-animation-timing-function:      step-start; /* Opera 12+ */
	animation-timing-function:         step-start; /* IE 10+, Fx 29+ */
}