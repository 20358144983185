@import 'color';

.sp-box {
	font-family: PTSans-NarrowBold;
	overflow: hidden;
	@include bg-color-default();

	h2 {
		font-size: 1.25em;
		padding: .5em 1em;
		margin: 10px 0 0 0;
		color: white;
		background-color: $c-header;
	}

	.sp-icon.sp-icon-maintenance {
		width: 100%;
		height: 100px;
		text-align: center;

		@media (min-width: 768px) { height: 150px; }
		@media (min-width: 992px) { height: 200px; }

	}

	.sp-warning-msgs {
		width: 100%;

		p {
			text-align: center;
			text-transform: uppercase;
			margin: 0;

			&:first-child { 
				font-size: 1.25em;
				@media (min-width: 768px) { font-size: 1.5em; }
				@media (min-width: 992px) { font-size: 2em; }
			}

			&:nth-child(2) { 
				font-size: 1em;
				@media (min-width: 768px) { font-size: 1.25em; }
				@media (min-width: 992px) { font-size: 1.5em; }
			}
		}
	}
}