@import 'color';

$betslip-width: 225px;

.sp-betslip-container {
	// float: right;
	min-height: 10px;
	display: none;
	width: $betslip-width;

	@media (min-width: 768px) { display: block; }
}

.sp-betslip-mobile .sp-betslip{
	display: block;
	/*float: none;*/
	width: 90%;
	margin: 10px auto;
}

.sp-betslip {
	font-size: .9em;
	/*float: right;*/
	@include bg-color-default();
	padding: 10px;
	/*margin-top: 10px;*/
	width: $betslip-width;
	display: none;
	top: 114px;
	right: 0;
	color: $c-pole-dark;

	@media (min-width: 768px) { display: block; }
	@media (min-width: 992px) { top: 0; }



	&.sp-betslip-hook {
		position: fixed;
		margin-top: 10px;
	}

	h3 {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		font-family: PTSans-NarrowBold;
	}

	ul.nav {
		width: 100%;
		font-family: PTSans-NarrowBold;

		li {
			// &:nth-child(1) { width: 25%; }
			// &:nth-child(2) { width: 50%; }
			// &:nth-child(3) { width: 25%; }
			width: calc(100%/3);
			&:nth-child(2) { 
				margin-left: -1px;
				margin-right: -1px;
			}

			a.nav-link {
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
				color: $c-pole-dark;
				font-weight: bolder;
				border-color: transparent;
				border-bottom-color: pole_condition($c-dark, $c-lighter);
				padding: 0.5rem 0.3125rem;

				&.active{
					background-color: $c-pole-light;
					border-color: pole_condition($c-dark, $c-lighter);
					border-bottom-color: transparent;
				}

				&.disabled { @include color-disabled(); }
			}
		}
	}

	.sp-bets {
		overflow: auto;
		background-color: $c-pole-light;

		ul {
			display: block;
			max-height: 150px;
			overflow-y: scroll;

			&>li {
				background-color: $c-primary;
				color: white;
				margin: 0;
				display: inline-block;
				width: 100%;
				position: relative;

				&::after {

					display: none;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					content: " ";
					background-color: $c-lighter;
					z-index: 10;
				}

				&.sp-inactive::after {

					display: block;
				}

				&:first-child { 
					border:	none;
					border-radius: 0;
				}
				&:last-child { border-radius: 0; }
				&+li { margin-top: 2px; }

			}

			.sp-bet-title, .sp-bet-detail { 
				width: 100%;
				text-align: left;
				padding-right: .6rem;

				* {
					float: right;
					color: white;
				} 
				a {
					cursor: pointer;
					font-size: 2rem;
					line-height: 1;
					color: $c-lighter;
					position: absolute;
					top: 4px;
					right: .9rem;
					text-decoration: none;
					z-index: 20;
				}
			}
		}

		.sp-betslip-controller {
			margin: 10px 5px;
			font-size: .9em;
			align-items: center;
			color: $c-pole-dark;

			.col, .col-1, .col-2, .col-3, .col-4, 
			.col-5, .col-6, .col-7, .col-8, .col-9,
			.col-10, .col-11, .col-12, .row {
				margin: 0;
				padding: 0;
			}

			button {
				width: 100%;
				height: 100%;
				padding: 3px;
				margin:	0 auto;
			}

			&>.row+.row { margin-top: 10px; }

			input {
				font-family: PTSans-Narrow;
				width: 100%;
				height: 100%;
				text-align: center;
				border: 1px solid #7c7c7c;
				border-radius: 5px;
			}
		}
	}



	.sp-betslip-status {

		margin-top: 10px;
		font-weight: bolder;
		div>span { float: right; }
	}

	.sp-bet-button {
		font-size: 1.2em;
		margin-top: 10px;
		width: 100%;
		font-family: PTSans-NarrowBold !important;
	}
}

.sp-sliptips:not(:empty) {
	
	& ~ .sp-slip-empty { display: none }
	& ~ .sp-slip-noempty { display: block }
} 
.sp-sliptips:empty {
	
	& ~ .sp-slip-empty { display: block }
	& ~ .sp-slip-noempty { display: none }
}