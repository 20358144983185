@mixin linear-gradient-tobottom($color1, $color2) {
	background: $color1;
    background: -webkit-linear-gradient(top, $color1, $color2); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(bottom, $color1, $color2); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(bottom, $color1, $color2); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to bottom, $color1, $color2); /* Standard syntax (must be last) */
}

@mixin linear-gradient-totop($color1, $color2) {
  @include linear-gradient-tobottom($color2, $color1);
}

@mixin rotate($angle) {
  -webkit-transform: rotate($angle); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
  -ms-transform: rotate($angle); /* IE 9 */
  transform: rotate($angle);
}

$c-body: #FFFFFF;
$c-header: #282828;
$c-default: #E8E8E8;
$c-primary: #000082;
$c-primary-grad: #0000BE, #000082;
$c-secondary-grad: #444142, #272324;
$c-item-1: #DEDEDE;
$c-item-2: #DEDEDE;
$c-disabled: #B7B7B7;
$c-error: #D10505;

$isinverted: 'false';
$c-pole-light: white;
$c-pole-dark: black;

$c-lighty: rgba(255, 255, 255, .15);
$c-light: rgba(255, 255, 255, .45);
$c-lighter: rgba(255, 255, 255, .7);
$c-lightest: rgba(255, 255, 255, .95);

$c-darky: rgba(0, 0, 0, .15);
$c-dark: rgba(0, 0, 0, .45);
$c-darker: rgba(0, 0, 0, .7);
$c-darkest: rgba(0, 0, 0, .95);

$c-success: #5CB85C;
$c-warning: #F0AD4E;
$c-danger: #D9534F;

@function pole_condition($light-side, $dark-side) {
  $case:'';
  @if ($isinverted == 'true') {

    $case: $dark-side;
  } @else {

    $case: $light-side;
  }
  @return $case
}

@mixin color-default() { color: $c-default; }
@mixin bg-color-default() { background-color: $c-default; }
@mixin color-active() { color: $c-primary; }
@mixin bg-color-active() { background-color: $c-primary; }
@mixin color-non-active() { color: $c-item-1; }
@mixin bg-color-non-active() { background-color: $c-item-1; }
@mixin color-disabled() { color: $c-disabled; }
@mixin bg-color-disabled() { background-color: $c-disabled; }

@mixin color-success() { color: $c-success; }
@mixin bg-color-success() { background-color: $c-success; }
@mixin color-warning() { color: $c-warning; }
@mixin bg-color-warning() { background-color: $c-warning; }
@mixin color-danger() { color: $c-danger; }
@mixin bg-color-danger() { background-color: $c-danger; }

@mixin bg-img-primary() { @include linear-gradient-tobottom($c-primary-grad...); }
@mixin bg-img-primary-active() { @include linear-gradient-totop($c-primary-grad...); }
@mixin bg-img-secondary() { @include linear-gradient-tobottom($c-secondary-grad...); }
@mixin bg-img-secondary-active() { @include linear-gradient-totop($c-secondary-grad...); }
