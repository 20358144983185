@import "color";

.sp-container {
  width: 100%;
  max-width: 1500px;

  @media (min-width: 1500px) {
    margin: 0 auto;
  }
}

.sp-icon {
  display: inline-block;

  img,
  svg {
    max-height: 100%;
    max-width: 100%;
  }
}

.sp-icon[disabled] {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.sp-hasHelper {
  position: relative;

  .sp-icon {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.sp-button {
  position: relative;
  box-shadow: none;
  border: none;
  padding: 8px;
  color: white;
  font-family: PTSans-NarrowBold;
  border-radius: 5px;
  @include bg-img-primary();

  &:active {
    @include bg-img-primary-active();
  }
}

.sp-button-sec {
  @extend .sp-button;
  color: $c-pole-light;
  @include bg-img-secondary();
  &:active {
    @include bg-img-secondary-active();
  }

  &.active {
    @include bg-img-primary();
    &:active {
      @include bg-img-primary-active();
    }
  }

  &.disabled {
    background: none;
    pointer-events: none;
    background-color: $c-disabled;
    &:active {
      background: none;
      background-color: $c-disabled;
    }
  }
}

.sp-button-success {
  @extend .sp-button;
  background-image: none;
  @include bg-color-success();
  &:active {
    background: none;
    @include bg-color-success();
  }
}

.sp-button-warning {
  @extend .sp-button;
  background-image: none;
  @include bg-color-warning();
  &:active {
    background: none;
    @include bg-color-warning();
  }
}

.sp-button-danger {
  @extend .sp-button;
  background-image: none;
  @include bg-color-danger();
  &:active {
    background: none;
    @include bg-color-danger();
  }
}

.sp-button-disabled {
  @extend .sp-button;
  color: white;
  background: none;
  background-color: $c-disabled;
  pointer-events: none;
  &:active {
    background: none;
    background-color: $c-disabled;
  }
}

.sp-button-mobile {
  padding: 0;
  margin: 0;
  background-color: $c-primary;

  box-sizing: border-box;
  border: none;
  width: 50px;
  height: 50px;
  padding: 10px;

  *[icon]:not([icon=""]) {
    width: 100%;
    height: 100%;
  }
}

.sp-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
  color: $c-pole-dark;

  &.active {
    display: block;
  }

  ul {
    position: absolute;
    display: flex;
    padding: 10px;
    flex-direction: column;
    z-index: 1000;

    background-color: $c-default;
    border: 2px solid $c-darky;
    border-radius: 5px;
    font-family: PTSans-NarrowBold;

    li,
    li button {
      list-style: none;
      width: 100%;
      text-align: left;
      white-space: nowrap;
    }

    li button {
      padding-left: 1em;
      padding-right: 1em;
    }

    &.sp-center li button {
      text-align: center;
    }

    li + li {
      margin-top: 10px;
    }
    .sp-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;
    }
  }
}

.sp-password {
  background-color: white;
  border-radius: 50%;

  input {
    border: 1px solid #d9d9d9;
    border-right: none;
  }

  span {
    border: 1px solid #d9d9d9;
    border-left: none;
    background-color: white;
  }

  input:focus ~ span {
    border-color: #5cb3fd;
  }
}

.sp-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.sp-aligncenter {
  align-self: center;
  text-align: center;
}

.tooltip-inner {
  white-space: pre-wrap;
}

.sp-totop-btn {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  box-sizing: content-box;
  width: 3rem;
  height: 3rem;
  background: none;
  border: none;
  display: none;
  z-index: 1000;
}

button:active,
button:focus,
*:focus {
  outline: none;
}

.sp-widgetcontainer {
  display: block;

  .sp-ratio {
    position: relative;
    padding: 0;
    padding-bottom: 56.25%;

    iframe,
    canvas {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      left: 0 !important;
    }
  }
}

// .sp-color-primary-grad { #0000BE, #000082; }
// .sp-color-secondary-grad { #444142, #272324; }
.sp-color-body {
  color: $c-body !important;
}
.sp-color-header {
  color: $c-header !important;
}
.sp-color-default {
  color: $c-default !important;
}
.sp-color-primary {
  color: $c-primary !important;
}
.sp-color-item-1 {
  color: $c-item-1 !important;
}
.sp-color-item-2 {
  color: $c-item-2 !important;
}
.sp-color-disabled {
  color: $c-disabled !important;
}
.sp-color-pole-light {
  color: $c-pole-light !important;
}
.sp-color-pole-dark {
  color: $c-pole-dark !important;
}
.sp-color-error {
  color: $c-error !important;
}

.sp-color-lighty {
  color: $c-lighty;
}
.sp-color-light {
  color: $c-light;
}
.sp-color-lighter {
  color: $c-lighter;
}
.sp-color-lightest {
  color: $c-lightest;
}
.sp-color-darky {
  color: $c-darky;
}
.sp-color-dark {
  color: $c-dark;
}
.sp-color-darker {
  color: $c-darker;
}
.sp-color-darkest {
  color: $c-darkest;
}

.sp-bg-color-body {
  background-color: $c-body !important;
}
.sp-bg-color-header {
  background-color: $c-header !important;
}
.sp-bg-color-default {
  background-color: $c-default !important;
}
.sp-bg-color-primary {
  background-color: $c-primary !important;
}
.sp-bg-color-item-1 {
  background-color: $c-item-1 !important;
}
.sp-bg-color-item-2 {
  background-color: $c-item-2 !important;
}
.sp-bg-color-disabled {
  background-color: $c-disabled !important;
}
.sp-bg-color-pole-light {
  background-color: $c-pole-light !important;
}
.sp-bg-color-pole-black {
  background-color: $c-pole-dark !important;
}
.sp-bg-color-error {
  background-color: $c-error !important;
}

.sp-color-white {
  color: white !important;
}
.sp-color-black {
  color: black !important;
}
.sp-color-active {
  color: $c-primary !important;
}
.sp-color-disabled {
  color: $c-disabled !important;
}

.sp-float-none {
  float: none;
}
.sp-float-left {
  float: left;
}
.sp-float-right {
  float: right;
}

.sp-bold {
  font-family: PTSans-NarrowBold !important;
}
.sp-notbold {
  font-family: PTSans-Narrow !important;
}
.sp-wrapple {
  white-space: normal !important;
}
.sp-hide-empty:empty {
  display: none;
}

.sp-flipx {
  transform: scaleX(-1);
}
.sp-flipy {
  transform: scaleY(-1);
}
.sp-rotate90 {
  transform: rotateZ(90deg);
}
.sp-rotate-90 {
  transform: rotateZ(-90deg);
}

.sp-text-nooverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sp-txt-xs {
  font-size: 0.8rem !important;
}
.sp-txt-sm {
  font-size: 0.9rem !important;
}
.sp-txt-md {
  font-size: 1rem !important;
}
.sp-txt-lg {
  font-size: 1.5rem !important;
}
.sp-txt-xl {
  font-size: 2rem !important;
}

.sp-txt-sm-xs {
  @media (min-width: 768px) {
    font-size: 0.8rem !important;
  }
}
.sp-txt-sm-sm {
  @media (min-width: 768px) {
    font-size: 0.9rem !important;
  }
}
.sp-txt-sm-md {
  @media (min-width: 768px) {
    font-size: 1rem !important;
  }
}
.sp-txt-sm-lg {
  @media (min-width: 768px) {
    font-size: 1.5rem !important;
  }
}
.sp-txt-sm-xl {
  @media (min-width: 768px) {
    font-size: 2rem !important;
  }
}

.sp-txt-md-xs {
  @media (min-width: 992px) {
    font-size: 0.8rem !important;
  }
}
.sp-txt-md-sm {
  @media (min-width: 992px) {
    font-size: 0.9rem !important;
  }
}
.sp-txt-md-md {
  @media (min-width: 992px) {
    font-size: 1rem !important;
  }
}
.sp-txt-md-lg {
  @media (min-width: 992px) {
    font-size: 1.5rem !important;
  }
}
.sp-txt-md-xl {
  @media (min-width: 992px) {
    font-size: 2rem !important;
  }
}

.sp-txt-lg-xs {
  @media (min-width: 1200px) {
    font-size: 0.8rem !important;
  }
}
.sp-txt-lg-sm {
  @media (min-width: 1200px) {
    font-size: 0.9rem !important;
  }
}
.sp-txt-lg-md {
  @media (min-width: 1200px) {
    font-size: 1rem !important;
  }
}
.sp-txt-lg-lg {
  @media (min-width: 1200px) {
    font-size: 1.5rem !important;
  }
}
.sp-txt-lg-xl {
  @media (min-width: 1200px) {
    font-size: 2rem !important;
  }
}

.tooltip-inner {
  color: $c-pole-light !important;
  background-color: $c-pole-dark !important;
  &::before {
    border-left-color: $c-pole-dark !important;
  }
}

.sp-content-condition {
  &:empty ~ .sp-content-condition-empty,
  &:not(:empty) ~ .sp-content-condition-notempty {
    display: block;
  }

  &:empty ~ .sp-content-condition-notempty,
  &:not(:empty) ~ .sp-content-condition-empty {
    display: none;
  }
}

table {
  background: white;
  line-height: 1.2;
  font-family: PTSans-Narrow;
}

.sp-iframe-16x9 {
  position: relative;
  padding: 0;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
