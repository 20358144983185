@import 'color';

.sp-navbar {
  padding: 0;
  background-color: $c-header;
  border-bottom: 2px solid $c-lighter;
  position: fixed;
  width: 100%;
  z-index: 999;

  &.sp-nofix { position: relative; }

  @media (min-width: 992px){ position: static; }

  & + .sp-navbar-space {
    height: 114px;
    width: 100%;
    @media (min-width: 992px){ display: none; }
  }

  .sp-navbar-mobile {
    display: flex;
    border-bottom: 2px solid $c-lighter;
    color: white;

    box-sizing: content-box;
    height: 50px;
    @media (min-width: 992px){
      display: none;
    }

    span {
      position: absolute;
      top: 0;
      left: 50px;
      font-size: .8rem;
      line-height: 1em;
      padding: calc((50px - 1em) / 2) .7em;
    }

    .sp-icon {
      height: 100%;
      width: 100%;
    }

    .sp-logo {
      position: relative;
      margin: 0 auto;

      box-sizing: content-box;
      height: 40px;
      padding: 5px 0;
    }

    .sp-mobile-betslip { 
      position: relative; 
      @media (min-width: 768px) { display: none; }

      mark {
        &:empty {
          display: none;
        }
        position: absolute;
        top: 2px;
        left: 2px;
        font-size: .7em;
        background-color: red;
        color: white;
        border-radius: 50%;
        padding: 2px;
        // width: 22px;
        // height: 22px;
        width: 1.8em;
        line-height: 1.5em;
      }
    }
  }

  .sp-menu {
    overflow: hidden;
    height: 60px;
    border: none;
    font-family: PTSans-NarrowBold;

    ul {
      box-sizing: content-box;
      overflow-y: hidden;

      overflow-x: scroll;
      margin: 0;
      /*display: flex;*/
      /*flex-flow: row;*/
      display: block;
      white-space: nowrap;
      @media (min-width: 992px) { margin-right: .5rem; }

      li {

        display: inline-block;

        &.active { @include bg-color-active(); }

        &:not(.active) a:hover,
        &:not(.active) a:focus { background-color: $c-darky; }

        a {
          text-decoration: none;

          &.nav-link {
            cursor: pointer;
            font-size: 1.2em;
            padding: 18px 10px;
            line-height: 24px;
            white-space: nowrap;
            color: white;

            box-sizing: border-box;
            width: 110px;
            text-align: center;
          }
        }
      }
    }
  }



  .sp-user {
    margin-left: auto;
    height: 60px;
    align-items: center;

    &>.sp-userbalance {
      font-size: 1.2rem;
      margin: 0 1rem;
      color: white;
      align-self: center;
    }

    &>.sp-icon {
      height: 100%; 
      background: none;
      width: 30px;
      padding: 15px 0;

	    &.sp-usericon {
        @include bg-color-active();
        width: 60px;
        padding: 15px;
	    }
    }

    &+.sp-dropdown {
      white-space: nowrap;

      button {
        text-align: center;
      }
    }
  }

  .sp-form {
    display: none;
    width: auto !important;
    margin-left: auto;
    margin-right: 10px;
    min-width: 31rem;

    @media (min-width: 992px){ display: block; }

    * { font-family: PTSans-NarrowBold; }

    input.sp-button { padding: 8px 20px; }
  }
}






.sp-breadcrumb {
  display: none;
  background-color: $c-header;
  border-radius: 0;
  margin: 0;

  padding: 5px 10px;
  line-height: 1.2em;
  list-style: none;

  @media (min-width: 992px){ display: block; }
  
  .breadcrumb-item, a { color: white; }

  .breadcrumb-item+.breadcrumb-item::before {
      display: inline-block;
      padding-right: .5rem;
      padding-left: .5rem;
      color: white;
      content: ">";
  }
}




.sp-banner {
  width: 100%;
  display: none;
  position: relative;
  @media (min-width: 992px){ display: block; }

  img { width: 100%; }
  .sp-logo {
    position: absolute;
    top: 15%;
    left: 3%;
    height: 70%;
    width: auto;
    font-size: 4.5vw;
    @media (min-width: 1500px){ font-size: 67.5px; }
  }
}
