@import 'color';

.sp-results {
	width: 100%;
	font-family: PTSans-NarrowBold;
	color: $c-pole-dark;

	&.sp-results-hasdetail {
		@media (min-width: 768px) { width: 50%; }
	}

	.sp-block-header {
		select { max-width: 250px; }
	}

	.sp-event {

		.sp-event-score {

			@include bg-color-active();
			color: white;
			line-height: 300%;
			margin: 0 10px;

			@media (min-width: 768px){
				background: none;
				color: $c-pole-dark;
				line-height: 100%;
				margin: 0;
			}
		}

		.sp-icon{

			&.sp-sport-icon,
	    &.sp-icon-sportid,
			&.sp-lens-icon
			{ display: inline-block; }

		}
	}
}

.sp-results-detail {
	width: 100%;
	float: left;
	display: none;
	position: relative;

	&>div {
		right: 0;
		width: 100%;
		height: auto;
	}

	&.sp-results-hasdetail { 
		margin-top: 10px;	

		@media (min-width: 768px) {
			display: block;
			width: calc(50% - 10px);
			float: right;
		}
	}
}

.sp-resultsdetail-mobile {

	display: block;
	@media (min-width: 768px) { display: none; }
}