@import 'color';


.sp-menu-2nd {

	width: 100%;
	overflow: hidden;
	@include bg-color-default();
  font-family: PTSans-NarrowBold;
  
	ul {
		box-sizing: content-box;
		overflow-y: hidden;
		overflow-x: scroll;
		margin: 0;
		// display: flex;
		display: block;
		flex-flow: row;
		white-space: nowrap;

		li {
			text-transform: none;
			padding: 2px 10px;
      cursor: pointer;
      background-color: $c-item-1;
			white-space: normal;
			color: $c-pole-dark;

			// display: flex;
			display: inline-block;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			
			@media (min-width: 992px) {
				float: left;
			}

			&.sp-active {
				color: white;
				@include bg-color-active();
				&:hover,
				&:active { color: white; }
			}

			a { 
				min-width: 100px;
				text-align: center;
				padding: 0;
				font-weight: bolder;
				
				

				@media (min-width: 992px) {
					display: flex;
					box-sizing: content-box;
					height: 1.875rem;
					line-height: 1.875rem;
					min-width: 0;
				}
			}

			&.sp-menu-2nd-other-compact {
				display: none; 
			
				@media (min-width: 992px) { display: inline-block; }
			
				.sp-icon { 
					@include rotate(180deg); 
					img { height: 14px; }
				}
				svg {
					display: block;
					margin: auto;
					width: 0.875rem;
				}
			}
			
			&.sp-menu-2nd-language {
				@extend .sp-menu-2nd-other-compact;
				float: right;
			}
			
			&.sp-menu-2nd-theme {
				@extend .sp-menu-2nd-other-compact;
				margin-left: auto;
				float: right;
			}
			
			&.sp-menu-2nd-other { 
				@media (min-width: 992px) { display: none; }
			}
    }
  }

	.sp-icon {
		width: 100%;
		height: 25px;
		text-align: center;

		@media (min-width: 992px) { 
			width: 25px;
			height: 100%;
		}
	}
}